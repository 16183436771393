/* @font-face{
	font-family:"Assistant Regular";
	src:url("../fonts/Assistant-Regular.ttf") format("truetype");
} */


body {
  position: relative;
  width: 100%;
  float: left;
  height: 100vh;
  opacity: 1;
  touch-action: none;
  font-family:"Assistant Regular", sans-serif;
  color: #fff;
  overflow: hidden;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  touch-action: none;
  background: #444;
}

body::-webkit-scrollbar { width: 0 !important }

header {
  float: left;
  width: 100%;
  padding: 0;
  background: rgb(8,8,8);
  box-shadow: 0 2px 10px rgba(0,0,0,0.25);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

header.tall {
  height: 120px;
}

.headerRow {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}


header h1 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  font-size: 22px;
  width: 100%;
}

header h2 {
  font-weight: 200;
  margin: 0;
  padding: 0;
  font-size: 22px;
  width: 100%;
}

header #headerText {
  top: 0;
  left: 0;
  width: 100%;
  float: left;
  text-align: center;
  padding: 0 0 0 0;
  z-index: 2;
}



/* Login */

#loginForm {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100vh - 200px);
}

#loginForm form {
  /* width: 300px;
  height: 300px; */
}

#loginForm .formField {
  width: 100%;
  clear: left;
  float: left;
  text-align: center;
  margin: 0 0 20px 0;

}


#loginForm input {
  -webkit-appearance: none;
  border: none;
  border-bottom: 2px solid #000;
  width: 94%;
  /* margin-left: 3%; */
  text-align: center;
  font-size: 29px;
  color: #000;
}


#logOut, #deleteMedia {
  /* float: right; */
  color: #c8c8c8;
  position: fixed;
  top: 28px;
  right: 18px;
  font-size: 14px;
  cursor: pointer;
}

.playlistForm button {
  -webkit-appearance: none;
  border: none;
  border: 2px solid #000;
  padding: 10px 30px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  background: #fff;
  margin: 20px 0 0 0;
  cursor: pointer;
}

#loginForm input#loginSubmit {
  -webkit-appearance: none;
  border: none;
  border: 2px solid #000;
  padding: 10px 30px;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  width: auto;
  display: inline-block;
  background: #fff;
  margin: 20px auto;
  float: none;
}

/* Player */


.carousel {
  max-width: 810px;
 text-align: right;
 float: right;
 clear: right;
}

#playerPage {
  width: 100vw;
  height: 100vh;
  max-width: 810px;
  float: right;
}

.swiper-container {
  height: 20vh;
  width: 100%;

}


img.slideImage {
  width: auto;
  height: 100%;
}

img.slideImage.portrait {
  width: auto;
  height: 100%;
}
